// This file is automatically generated by `generated_js` task. Any changes will be lost
/* tslint:disable */
export const GoCDVersion = {
  "version": "24.4.0",
  "buildNumber": "19686",
  "gitSha": "4e34832acbaf77d46bca61ccc4b0f8d458831a31",
  "fullVersion": "24.4.0-19686",
  "formattedVersion": "24.4.0 (19686-4e34832acbaf77d46bca61ccc4b0f8d458831a31)",
  "copyrightYear": "2024"
}


function stripLeadingPrefix(suffix: string, prefix: string) {
  if (suffix.startsWith(prefix)) {
      suffix = suffix.substring(1);
  }
  return suffix;
}

export function docsUrl(suffix: string = '') {
  return `https://docs.gocd.org/24.4.0/${stripLeadingPrefix(suffix, '/')}`
}

export function apiDocsUrl(suffix: string = '') {
  return `https://api.gocd.org/24.4.0/${stripLeadingPrefix(suffix, '#')}`
}
    
